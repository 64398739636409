body{
  margin :10px

}
 

.navbar {
    background-color: #1f1979;
    padding: 20px 20px;
    text-align: right;
    border-radius: 10px;
 
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar li {
    display: inline;
    margin-right: 20px;

  }
  .log{
    background-color: #ffa500;
    color: white;
    font-size: 20px;
    margin: 15px 20px;
    padding: 5px 30px;
    border-radius: 25px;
    text-align: center;
   }
  
  .navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
    margin: 10px;
    padding: 10px;

  }
  .divi{
    background-color: #1f1979;
    display: flex;
    border-radius: 8px;

  }
  
  .navbar a:hover {
    color: #ffa500;
  }
  
  .navbar .active {
    color: #ffa500; /* Active link color */
    font-weight: bold; /* Bold font for active link */
  }

  .imm{
      height: 30px;
  }

  .span1{
    font-weight: bold;
    font-size: 25px;
  }
  .span3{
    color: #ffa500;  }

  .span2{
    font-weight: bold;
    font-size: 25px;

    color: #1f1979;

   }


   .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 180px;
    margin:10px 2px;
    height:380px;

  }
  .avatar{
    width:160px;
    margin: 10px;
    height: 200px;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .container {
    padding: 2px 16px;
  }
  .btnn{
    border: none;
    padding: 5px 20px;
    margin: 5px;
    text-align: right;
    color:#1f1979;
    font-weight: bold;
    font-size: 16px;
    border-radius: 20px;
    text-decoration: none;
    background-color: #ffa500;
   }

  .avat{
    text-align: center;

  }
 .p{
    font-size: 20px;
    margin: 20px;
    padding: 20px;
  }
.acceuil{
  margin: 0 20px;
  display: flex;
  justify-content: space-around;
}
  


  @media only screen and (max-width: 768px) {
   .divi{
    display: block;
   }
   
 
    .navbar {
      text-align: center;
    }
  
    .navbar ul {
      margin-top: 20px;
    }
  
    .navbar li {
      display: block;
      margin: 10px 0;
    }

    .log{
      background-color: #ffa500;
      color: white;
      font-size: 20px;
      padding: 10px 20px;
      border-radius: 0;
      text-align: center;
      margin: 0;
     }

     .navbar ul {
      margin-top: 0;
    }

    .navbar li {
       margin: 5px;
    }
    .imm{
      display: none;
    }
    .acceuil{
  
      display: block;
 
    }
    .card{
      width: 100%;
      text-align: center;
    }


  }